import { Text } from 'mpharma-i18n';
import React from 'react';

export const BASE_NAME = process.env.REACT_APP_BASE_NAME;
export const PUBLIC_URL = process.env.PUBLIC_URL;
export const API_ROOT = process.env.REACT_APP_API_ROOT;
export const BASE_URL = `${API_ROOT}/cs-bff`;
export const LOGOUT_URL = process.env.REACT_APP_LOGOUT_URL;
export const AUTH_URL = process.env.REACT_APP_AUTH_URL;
export const APP_URL = process.env.REACT_APP_API_ROOT;
export const LOGISTICS_APP_URL = process.env.REACT_APP_LOGISTICS_APP_URL;
export const APP_RELEASE = `${process.env.REACT_APP_NAME}@${process.env.REACT_APP_VERSION}`;
export const APP_SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN;
export const APP_VERSION = `v${process.env.REACT_APP_VERSION}`;

export const DRAWER_WIDTH = 56;

export const APP_ENV = process.env.REACT_APP_ENV;
export const OOP_BFF_BASE = APP_ENV === 'staging' ? process.env.REACT_APP_BFF_URL : `${API_ROOT}/oop-bff`;
export const INVENTORY_BFF_URL = API_ROOT + '/inventory-bff';
export const INVENTORY_OPERATIONS_TRACKING_URL = INVENTORY_BFF_URL + '/track-operations';

export const links = [{ url: 'users/userinfo' }, { url: 'oop-bff/country-config/' }];

export const INTERCOM_APP_ID = process.env.REACT_APP_INTERCOM_APP_ID;
export const INTERCOM_SECRET = process.env.REACT_APP_INTERCOM_SECRET;

export const INVENTORY_OPERATION_TYPES = {
  inventory: {
    download: 'ID'
  },
  sales: {
    download: 'SD'
  },
  patients: {
    download: 'PD'
  },
  batches: {
    download: 'BD'
  },
  promos: {
    download: '0D'
  }
};

export const EXPIRING_SOON_MAX_NUMBER_OF_DAYS = 90;
export const EXPIRING_SOON_MIN_NUMBER_OF_DAYS = 1;

export const ORDERS_MODULE_COUNTRY_CONFIGS = {
  GH: {
    useMarketPlace: true
  },
  NG: {
    useMarketPlace: true
  },
  GA: {
    useMarketPlace: true
  },
  KE: {
    useMarketPlace: false
  },
  ZM: {
    useMarketPlace: false
  },
  RW: {
    useMarketPlace: false
  },
  NW: {
    useMarketPlace: false
  },
  ET: {
    useMarketPlace: false
  },
  UG: {
    useMarketPlace: false
  },
  SN: {
    useMarketPlace: false
  },
  TG: {
    useMarketPlace: false
  },
  MW: {
    useMarketPlace: false
  },
  BJ: {
    useMarketPlace: true
  }
};
export const BROADCAST_CHANNEL_NAMES = {
  PRICE_UPLOAD_PROGRESS_CHANNEL: 'price-upload-progress-channel',
  DELIVERY_NOTE_UPLOAD_PROGRESS_CHANNEL: 'delivery-note-upload-progress-channel',
  STOCK_UPLOAD_PROGRESS_CHANNEL: 'stock-upload-progress-channel',
  CUSTOM_PRODUCT_UPLOAD_PROGRESS_CHANNEL: 'custom-product-upload-progress-channel',
  SALES_SYNC_STATUS_BROADCAST_CHANNEL: 'sales-sync-channel'
};

export const FILE_UPLOADS_STATUS = {
  STARTED: 'started',
  IN_PROGRESS: 'inprogress',
  COMPLETED: 'completed'
};

export const COUNTRIES_WITH_ENABLED_FACILITY_MANAGEMENT = ['GA', 'ET'];

export const GROUPED_UPDATING_REASONS_OPTIONS = [
  {
    label: <Text i18nKey="home.updateApplication.reasons.pricing">Update pricing</Text>,
    value: 'Update pricing'
  },
  {
    label: <Text i18nKey="home.updateApplication.reasons.products">Update product list</Text>,
    value: 'Update product list'
  },
  {
    label: <Text i18nKey="home.updateApplication.reasons.see.pricing">Can't see product pricing</Text>,
    value: "Can't see product pricing"
  },
  {
    label: <Text i18nKey="home.updateApplication.reasons.find.products">Can't find products</Text>,
    value: "Can't find products"
  },
  {
    label: <Text i18nKey="home.updateApplication.reasons.bloom.unresponsive">Bloom not being responsive</Text>,
    value: 'Bloom not being responsive'
  },
  {
    label: <Text i18nKey="home.updateApplication.reasons.others">Others</Text>,
    value: 'Others'
  }
];

export const DATABASE_NAMES = {
  BLOOM: 'bloom',
  OUTBOX: 'outbox'
};

export const PRICE_UPLOAD_PROCESSING_STATUS = {
  VALIDATING_FILE: 'VALIDATING_FILE',
  VALIDATING_PRICES: 'VALIDATING_PRICES',
  FAILED_VALIDATION: 'FAILED_VALIDATION',
  RETRIEVING_FACILITY_DETAILS: 'RETRIEVING_FACILITY_DETAILS',
  PROCESSING_PRICES: 'PROCESSING_PRICES',
  COMPLETED: 'COMPLETED',
  QUEUED_UPLOAD: 'QUEUED_UPLOAD'
};

export const CURRENT_PRICE_UPLOAD_COUNTRY = 'current-price-upload-country';
export const CURRENT_PRICE_UPLOAD_FACILITY = 'current-price-upload-facility';

export const RESOURCE_TITLE = {
  INVENTORY: 'Inventory',
  PRICE: 'Price',
  PATIENT_LIST: 'Patient list',
  SALES_HISTORY: 'Sales history',
  PROMOTIONS_AND_DISCOUNTS: 'Promotions and Discounts',
  SALES: 'Sales',
  PRICE_LIST: 'Price list',
  DELIVERY_NOTE: 'Delivery Note',
  STOCK_COUNT: 'Stock count',
  CUSTOM_PRODUCT: 'Custom Product'
};

export const SALES_SYNC_PARTIAL = 'salesSyncPartial';
export const SALES_SYNC = 'salesSync';
