import React from 'react';
import useOfflineStatus from '../../../hooks/useOfflineStatus';
import './index.css';

const color = {
  offline: 'rgba(0,0,0,0.25)',
  online: '#44804C'
};

function InternetSign() {
  const { online, message } = useOfflineStatus();
  const className = message ? 'offline-box-animate' : '';
  const textStyle = message ? 'message' : '';

  return (
    <div className={`offline-box ${className}`} data-testid="internet-sign">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="26"
        height="30"
        viewBox="0 0 26 30"
        fill={online ? color.online : color.offline}
      >
        <g>
          <path fill="none" d="M0 0h24v24H0z" />
          <path d="M1 9l2 2c4.97-4.97 13.03-4.97 18 0l2-2C16.93 2.93 7.08 2.93 1 9zm8 8l3 3 3-3c-1.65-1.66-4.34-1.66-6 0zm-4-4l2 2c2.76-2.76 7.24-2.76 10 0l2-2C15.14 9.14 8.87 9.14 5 13z" />
        </g>
      </svg>
      <p className={`${textStyle}`}>{message}</p>
    </div>
  );
}

export default InternetSign;
