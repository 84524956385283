import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import logo from '../../images/logo.png';
import { ReactComponent as CloseIcon } from '../../images/close.svg';

const LanguageNotification = ({ action, critical, handleClose, link, message, warning, info, ...rest }) => (
  <Wrapper
    role="alert"
    critical={critical}
    warning={warning}
    info={info}
    {...rest}
    data-testid="language-notification-wrapper"
  >
    <Container>
      <div>
        <img src={logo} alt="logo" height="40px" />
      </div>
      <Content>
        <Link href={link}>{action}</Link>
        <Text data-testid="language-notification-message">{message}</Text>
      </Content>
    </Container>
    <CloseIcon style={closeIcon} aria-label="close" onClick={() => handleClose()} data-testid />
  </Wrapper>
);

const colors = {
  critical: '#ff647c',
  warning: '#fdc300',
  info: '#3FDA98'
};

const types = {
  critical: css`
    border: 1px solid ${colors['critical']};
    border-left: 8px solid ${colors['critical']};
  `,
  warning: css`
    border: 1px solid ${colors['warning']};
    border-left: 8px solid ${colors['warning']};
  `,
  info: css`
    border: 1px solid ${colors['info']};
    border-left: 8px solid ${colors['info']};
  `
};

const Wrapper = styled.div`
  width: 431px;
  height: 92px;
  display: flex;
  padding: 17px 9px;
  position: fixed;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.06);
  box-sizing: border-box;
  align-items: center;
  font-family: Sofia Pro,serif;
  border-radius: 3px 5px 5px 3px;
  justify-content: space-between;
  background-color: #fff;
  ${props => props.critical && types['critical']};
  ${props => props.warning && types['warning']};
  ${props => props.info && types['info']};
  bottom: 20px;
  left: 52px;
  z-index: 1200;
}
`;

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Content = styled.div`
  margin-left: 16px;
`;

const Text = styled.p`
  color: rgba(0, 0, 0, 0.89);
  font-size: 14px;
  font-family: Sofia Pro, serif;
  line-height: 19px;
  letter-spacing: 0;
`;

const Link = styled.a`
  color: black;
  font-size: 14px;
  font-weight: bold;
  line-height: 20px;
`;

const closeIcon = {
  backgroundColor: 'rgba(238, 241, 249, 0.5)',
  height: '20px',
  width: '20px',
  borderRadius: '50%',
  cursor: 'pointer',
  padding: '5px'
};

LanguageNotification.propTypes = {
  action: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  classes: PropTypes.object,
  critical: PropTypes.bool,
  handleClose: PropTypes.func.isRequired,
  link: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  warning: PropTypes.bool,
  info: PropTypes.bool
};

export default LanguageNotification;
