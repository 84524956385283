import { useState } from 'react';
import { BroadcastChannel as BroadcastChannelBackup } from 'broadcast-channel';
import { BROADCAST_CHANNEL_NAMES } from '../helpers/constants';

function useCustomProductUploadProgressBroadcastChannel() {
  const [customProductUploadProgress, setCustomProductUploadProgress] = useState(0);
  const [customProductErrorCount, setCustomProductErrorCount] = useState(0);
  const [customProductPageSwitched, setCustomProductPageSwitched] = useState(false);
  const BloomBroadcastChannel = typeof BroadcastChannel === 'undefined' ? BroadcastChannelBackup : BroadcastChannel;

  const customProductUploadProgressChannel = new BloomBroadcastChannel(
    BROADCAST_CHANNEL_NAMES.CUSTOM_PRODUCT_UPLOAD_PROGRESS_CHANNEL
  );

  customProductUploadProgressChannel.onmessage = e => {
    const { uploadPercentage, errorCount, pageSwitched } = e.data;
    if (uploadPercentage !== customProductUploadProgress) {
      setCustomProductUploadProgress(uploadPercentage);
    }
    if (errorCount !== customProductErrorCount) {
      setCustomProductErrorCount(errorCount);
    }
    if (pageSwitched !== customProductPageSwitched) {
      setCustomProductPageSwitched(pageSwitched);
    }
  };

  return [
    customProductUploadProgress,
    setCustomProductUploadProgress,
    customProductErrorCount,
    setCustomProductErrorCount,
    customProductPageSwitched,
    setCustomProductPageSwitched
  ];
}

export default useCustomProductUploadProgressBroadcastChannel;
