import { useState } from 'react';
import { BroadcastChannel as BroadcastChannelBackup } from 'broadcast-channel';
import { BROADCAST_CHANNEL_NAMES } from '../helpers/constants';

function useDeliveryNoteUploadProgressBroadcastChannel() {
  const [deliveryNoteUploadProgress, setDeliveryNoteUploadProgress] = useState(0);
  const [deliveryErrorCount, setDeliveryErrorCount] = useState(0);
  const [deliveryNotePageSwitched, setDeliveryNotePageSwitched] = useState(false);
  const BloomBroadcastChannel = typeof BroadcastChannel === 'undefined' ? BroadcastChannelBackup : BroadcastChannel;

  const deliveryNoteUploadProgressChannel = new BloomBroadcastChannel(
    BROADCAST_CHANNEL_NAMES.DELIVERY_NOTE_UPLOAD_PROGRESS_CHANNEL
  );

  deliveryNoteUploadProgressChannel.onmessage = e => {
    const { uploadPercentage, errorCount, pageSwitched } = e.data;
    if (uploadPercentage !== deliveryNoteUploadProgress) {
      setDeliveryNoteUploadProgress(uploadPercentage);
    }
    if (deliveryErrorCount !== errorCount) {
      setDeliveryErrorCount(errorCount);
    }
    if (pageSwitched !== deliveryNotePageSwitched) {
      setDeliveryNotePageSwitched(pageSwitched);
    }
  };

  return [
    deliveryNoteUploadProgress,
    setDeliveryNoteUploadProgress,
    deliveryErrorCount,
    setDeliveryErrorCount,
    deliveryNotePageSwitched,
    setDeliveryNotePageSwitched
  ];
}

export default useDeliveryNoteUploadProgressBroadcastChannel;
