import React from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { Text } from 'mpharma-i18n';
import { CircularProgress } from '@mui/material';

const PREFIX = 'ClearCacheLoadingModal';

const classes = {
  container: `${PREFIX}-container`,
  title: `${PREFIX}-title`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')({
  [`& .${classes.container}`]: {
    padding: '3rem 3rem 3rem 3rem',
    width: '500px',
    backgroundColor: '#ffffff',
    overflow: 'auto',
    boxShadow: '0 12px 33px 0 rgba(0, 0, 0, 0.1)'
  },
  [`& .${classes.title}`]: {
    color: 'rgba(0,0,0,0.89)',
    fontFamily: 'Sofia Pro',
    fontSize: '16px',
    lineHeight: '32px'
  }
});

export function ClearCacheLoadingModal({ open }) {
  return (
    <Root open={open}>
      <div className={classes.container}>
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', padding: '4rem' }}>
          <CircularProgress
            style={{
              margin: 'auto auto 2rem auto',
              color: '#FDC302'
            }}
            size={56}
          />
          <p className={classes.title}>
            <Text i18nKey="home.updateApplicationLoading">Saving un-sync data and clearing cache...</Text>
          </p>
        </div>
      </div>
    </Root>
  );
}

ClearCacheLoadingModal.propTypes = {
  open: PropTypes.bool
};

export default ClearCacheLoadingModal;
