import { patch } from '.';
import { AUTH_URL } from '../../helpers/constants';

export const savePin = async (data, success, error) => {
  try {
    await patch(`${AUTH_URL}/users/user_pin_reset/`, success, error, data);
  } catch (e) {
    error(e);
  }
};
