import React from 'react';
import styled, { keyframes } from 'styled-components';

function ProgressBar({ percentage }) {
  return (
    <div data-testid="progressbar" style={{}}>
      <Wrapper>
        <div className="meter">
          <Progress percentage={percentage} />
        </div>
      </Wrapper>
    </div>
  );
}

const fill = keyframes`
0% {
  background-position: 0 0;
}
100% {
  background-position: 50px 50px;
}
`;

const Progress = styled.span`
  display: block;
  height: 100%;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-top-left-radius: 5px;
  width: ${props => `${props.percentage}%`};
  border-bottom-left-radius: 5px;
  background-color: #35cae2;
  &::after,
  .animate > span {
    width: ${props => `${props.percentage}%`};
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-image: -webkit-gradient(
      linear,
      0 0,
      100% 100%,
      color-stop(0.25, #31b5ca),
      color-stop(0.25, transparent),
      color-stop(0.5, transparent),
      color-stop(0.5, #31b5ca),
      color-stop(0.75, #31b5ca),
      color-stop(0.75, transparent),
      to(transparent)
    );
    background-image: -moz-linear-gradient(
      -45deg,
      #31b5ca 25%,
      transparent 25%,
      transparent 50%,
      #31b5ca 50%,
      #31b5ca 75%,
      transparent 75%,
      transparent
    );
    z-index: 1;
    background-size: 50px 50px;
    animation: ${fill} 2s linear infinite;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    overflow: hidden;
  }

  .animate > span:after {
    display: none;
  }
`;
const Wrapper = styled.div`
  .meter {
    height: 8px;
    position: relative;
    background: #d9dfea;
    border-radius: 5px;
  }
`;

export default ProgressBar;
