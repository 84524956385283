import { useState, useEffect, useRef } from 'react';
import { API_ROOT } from '../helpers/constants';
import { get } from '../admin/api/';
import moment from 'moment';

const useBulkStockUploadNotifications = () => {
  const [notifications, setNotifications] = useState([]);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const isBulkUploader = localStorage.getItem('isBulkUploader');
  const intervalIdRef = useRef(10000);

  const fetchNotifications = async () => {
    setError(false);
    setLoading(true);
    try {
      const { results } = await get(
        `${API_ROOT}/extracts-ms/stock-transfers/?created_date=${moment().format('yyyy-MM-DD')}`
      );
      setNotifications(results);
    } catch (error) {
      setError(true);
      console.error('There was an error fetching bulk stock upload notifications', error);
      clearInterval(intervalIdRef.current);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isBulkUploader) {
      const setupInterval = async () => {
        await fetchNotifications();

        intervalIdRef.current = setInterval(() => {
          fetchNotifications();
        }, 10000);
      };

      setupInterval();

      return () => clearInterval(intervalIdRef.current);
    }
  }, [isBulkUploader]);

  return {
    bulkStockUploadNotifications: notifications,
    errorLoadingBulkNotifications: error,
    bulkStockUploadIsLoading: loading,
    fetchNotifications
  };
};

export default useBulkStockUploadNotifications;
