import { useState } from 'react';
import { BroadcastChannel as BroadcastChannelBackup } from 'broadcast-channel';
import { BROADCAST_CHANNEL_NAMES } from '../helpers/constants';

function useStockUploadProgressBroadcastChannel() {
  const [stockUploadProgress, setStockUploadProgress] = useState(0);
  const [stockErrorCount, setStockErrorCount] = useState(0);
  const [stockPageSwitched, setStockPageSwitched] = useState(false);
  const BloomBroadcastChannel = typeof BroadcastChannel === 'undefined' ? BroadcastChannelBackup : BroadcastChannel;

  const stockUploadProgressChannel = new BloomBroadcastChannel(BROADCAST_CHANNEL_NAMES.STOCK_UPLOAD_PROGRESS_CHANNEL);

  stockUploadProgressChannel.onmessage = e => {
    const { uploadPercentage, errorCount, pageSwitched } = e.data;
    if (uploadPercentage !== stockUploadProgress) {
      setStockUploadProgress(uploadPercentage);
    }
    if (errorCount !== stockErrorCount) {
      setStockErrorCount(errorCount);
    }
    if (pageSwitched !== stockPageSwitched) {
      setStockPageSwitched(pageSwitched);
    }
  };

  return [
    stockUploadProgress,
    setStockUploadProgress,
    stockErrorCount,
    setStockErrorCount,
    stockPageSwitched,
    setStockPageSwitched
  ];
}

export default useStockUploadProgressBroadcastChannel;
