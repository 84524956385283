import React, { useEffect, useState } from 'react';
import isOnline from 'is-online';
import { useMpharmaI18n } from 'mpharma-i18n';

export function withOfflineStatus(Component) {
  return function WrapperComponent(props) {
    const { online } = useOfflineStatus();
    return <Component {...props} online={online} />;
  };
}

export function useOfflineStatus() {
  const [online, setOnline] = useState(!!navigator?.onLine);
  const [message, setMessage] = useState('');
  const { translateKey } = useMpharmaI18n();

  const checkOnlineAvailability = async () => {
    const onlineUpdate = await isOnline();
    setOnline(onlineUpdate);
  };

  const handleOnline = () => {
    checkOnlineAvailability();
    fakeReconnection();
  };

  const handleOffline = () => {
    checkOnlineAvailability();
    setMessage(translateKey('connection.lost'));
    resetAnimation();
  };

  const fakeReconnection = () => {
    setMessage(translateKey('connection.attemptReconnect'));
    setTimeout(() => {
      setMessage(translateKey('connection.reconnected'));
      resetAnimation();
    }, 2000);
  };

  const resetAnimation = () => {
    setTimeout(() => {
      setMessage('');
    }, 2000);
  };

  useEffect(() => {
    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    // for unmounting
    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
    // eslint-disable-next-line
  }, []);

  return {
    online,
    message
  };
}

export default useOfflineStatus;
