import React, { useRef, useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import Dropdown from '@mui/icons-material/ExpandMore';
import ArrowDropUp from '@mui/icons-material/ExpandLess';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { getInitials } from '../../helpers/utils';

const PREFIX = 'CustomOfflineDropdown';

const classes = {
  container: `${PREFIX}-container`,
  selected: `${PREFIX}-selected`,
  listItemContainer: `${PREFIX}-listItemContainer`,
  avatar: `${PREFIX}-avatar`,
  userDetails: `${PREFIX}-userDetails`,
  userName: `${PREFIX}-userName`,
  userType: `${PREFIX}-userType`,
  optionsContainer: `${PREFIX}-optionsContainer`,
  selectedIndicator: `${PREFIX}-selectedIndicator`
};

const Root = styled('div')(() => ({
  [`& .${classes.container}`]: {
    background: 'none',
    border: 'none',
    width: 'inherit'
  },

  [`& .${classes.selected}`]: {
    height: '78px',
    minWidth: '340px',
    width: 'inherit',
    border: '1px solid rgb(233,236,240)',
    borderRadius: '10px',
    display: 'grid',
    gridTemplateColumns: '1fr 4fr 1fr',
    placeItems: 'center',
    padding: '0 8px',
    gridGap: '8px'
  },

  [`& .${classes.listItemContainer}`]: {
    height: '70px',
    minWidth: '100%',
    display: 'grid',
    gridTemplateColumns: '1fr 4fr 1fr',
    placeItems: 'center',
    padding: '0 8px',
    gridGap: '8px',
    cursor: 'pointer',
    outline: 'none',
    background: 'none',
    border: 'none',
    '&:hover': {
      backgroundColor: '#f9fafb'
    }
  },

  [`&.${classes.avatar}`]: {
    height: '40px',
    width: '40px',
    borderRadius: '50%',
    backgroundColor: '#587cc8',
    display: 'grid',
    placeContent: 'center',
    color: 'white'
  },

  [`& .${classes.userDetails}`]: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'start',
    width: '100%'
  },

  [`& .${classes.userName}`]: {
    padding: '0',
    margin: '0',
    fontSize: '14px'
  },

  [`& .${classes.userType}`]: {
    fontSize: '14px',
    color: '#788794',
    marginTop: '4px',
    textAlign: 'left'
  },

  [`& .${classes.optionsContainer}`]: {
    width: 'inherit',
    position: 'absolute',
    top: '98',
    marginTop: '12px',
    borderRadius: '12px',
    overflow: 'scroll',
    boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px',
    zIndex: '1000',
    backgroundColor: '#ffffff',
    maxHeight: '200px'
  },

  [`& .${classes.selectedIndicator}`]: {
    height: '10px',
    width: '10px',
    borderRadius: '50%',
    backgroundColor: '#40da98'
  }
}));

const ListItem = ({ user, onSelect = () => null, value }) => (
  <li
    className={classes.listItemContainer}
    onClick={() => {
      onSelect(user);
    }}
    data-testid="custom-offline-dropdown-list-item"
  >
    <Root className={classes.avatar}>{getInitials(user?.first_name, user?.last_name)}</Root>

    <div className={classes.userDetails}>
      <h5 className={classes.userName}>{`${user.first_name} ${user.last_name}`}</h5>
      <span id={`list_item`} className={classes.userType}>
        {user?.groups?.map((group, index) => `${group}${index !== user.groups.length - 1 ? ', ' : ''}`)}
      </span>
    </div>
    {value.username === user.username ? <CheckCircleIcon style={{ fontSize: '14px', color: '#40da98' }} /> : null}
  </li>
);

const CustomSelect = ({ value = {}, users, handleSelect, label }) => {
  const [open, setOpen] = useState(false);
  const ref = useRef(null);
  const initials = value?.first_name && value?.last_name ? getInitials(value?.first_name, value?.last_name) : '';

  const handleClickOutside = event => {
    if (ref.current && !ref.current.contains(event.target)) {
      setOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  return (
    <div className={classes.container} ref={ref} data-testid="custom-offline-dropdown">
      {label && <p style={{ fontSize: '14px', marginBottom: '4px', textAlign: 'start' }}>{label}</p>}
      <button
        style={{ backgroundColor: !open ? '#f9fafb ' : '#ffffff' }}
        className={classes.selected}
        onClick={() => setOpen(!open)}
        data-testid="custom-offline-dropdown-button"
      >
        <div className={classes.avatar}>{initials}</div>

        <div className={classes.userDetails}>
          <h4 className={classes.userName}>{`${value.first_name} ${value.last_name}`}</h4>
          <p className={classes.userType}>
            {value?.groups?.map((group, index) => `${group}${index !== value.groups.length - 1 ? ', ' : ''}`)}
          </p>
        </div>
        {open ? <ArrowDropUp style={{ color: '#d6d5d4' }} /> : <Dropdown style={{ color: '#d6d5d4' }} />}
      </button>

      {open && (
        <ul className={classes.optionsContainer}>
          {users
            ? users.map(
                (user, index) =>
                  user && (
                    <ListItem
                      key={`offline-users-${user.id || index}`}
                      user={user}
                      classes={classes}
                      value={value}
                      onSelect={() => {
                        handleSelect(user);
                        setOpen(!open);
                      }}
                    />
                  )
              )
            : null}
        </ul>
      )}
    </div>
  );
};

export default CustomSelect;
