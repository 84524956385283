import mixpanel from 'mixpanel-browser';
import React, { createContext, useContext } from 'react';
import PropTypes from 'prop-types';

const context = createContext(null);
export const Provider = context.Provider;
export const useMixpanel = () => useContext(context);

const defaults = {
  track_pageview: false // Rarely makes sense to track page views in React apps
};

const MixpanelProvider = ({ children, config }) => {
  config = Object.assign({}, defaults, config);
  mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN, config);
  return <Provider value={mixpanel}>{children}</Provider>;
};

MixpanelProvider.propTypes = {
  children: PropTypes.node,
  config: PropTypes.object
};

export default MixpanelProvider;
