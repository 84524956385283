import React from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { Badge } from '@mui/material';

const PREFIX = 'NotificationBadgeIcon';

const classes = {
  badge: `${PREFIX}-badge`,
  badgeColor: `${PREFIX}-badgeColor`
};

const StyledBadge = styled(Badge)(() => ({
  [`& .${classes.badge}`]: {
    width: 10,
    height: 10
  },

  [`& .${classes.badgeColor}`]: {
    backgroundColor: 'red'
  }
}));

const NotificationBadgeIcon = ({ children }) => {
  return (
    <StyledBadge
      color="primary"
      variant="dot"
      classes={{
        colorPrimary: classes.badgeColor,
        dot: classes.badge
      }}
    >
      {children}
    </StyledBadge>
  );
};

NotificationBadgeIcon.propTypes = {
  children: PropTypes.node
};

export default NotificationBadgeIcon;
