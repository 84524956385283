import * as singleSpa from 'single-spa';
import { handleMicroAppUnmount, setupMicroApp } from './utils';

const ordersUrl =
  process.env.NODE_ENV === 'development'
    ? 'http://localhost:4002/static/js/main.js'
    : process.env.REACT_APP_ORDERS_JS_BUNDLE;
const ordersElementId = 'orders';

const loadOrders = async () => {
  return setupMicroApp(ordersUrl, ordersElementId);
};

const application = {
  update: () => Promise.resolve(), //update function
  bootstrap: () => Promise.resolve(), //bootstrap function
  mount: () => Promise.resolve(loadOrders()), //mount function
  unmount: () => Promise.resolve(handleMicroAppUnmount()) //unmount function
};

export const registerOrdersApp = () => {
  singleSpa.registerApplication('orders', application, location => location.pathname.startsWith('/orders'));
};
