export const runScript = async url => {
  return new Promise((resolve, reject) => {
    const script = document.createElement('script');
    script.src = url;
    script.onload = resolve;
    script.onerror = reject;

    const firstScript = document.getElementsByTagName('script')[0];
    firstScript.parentNode.insertBefore(script, firstScript);
  });
};

export async function setupMicroApp(moduleUrl, moduleElementId) {
  let microAppModule = document.getElementById(moduleElementId);
  if (microAppModule && microAppModule.childElementCount === 0) {
    try {
      window.dispatchEvent(new CustomEvent('loading', { detail: { loading: true } }));
      await runScript(moduleUrl);
      window.dispatchEvent(new CustomEvent('loading', { detail: { loading: false } }));
      return window.reactApp;
    } catch (e) {
      handleMicroAppLoadingError();
    }
  }
}

export function isScriptSetup(moduleUrl) {
  try {
    return Boolean(document.querySelector(`script[src="${moduleUrl}"]`));
  } catch (e) {
    return false;
  }
}

export function handleMicroAppUnmount() {
  window.dispatchEvent(new CustomEvent('loading', { detail: { loading: false } }));
  return true;
}

export function handleMicroAppLoadingError() {
  window.dispatchEvent(new CustomEvent('loading', { detail: { loading: false, error: 'errors.loadingModule' } }));
}

export const matchingPathname = pathnames => location => pathnames.some(pathname => location.pathname === pathname);
