import * as singleSpa from 'single-spa';
import { handleMicroAppUnmount, setupMicroApp } from './utils';

const inventoryUrl =
  process.env.NODE_ENV === 'development'
    ? 'http://localhost:3001/static/js/main.js'
    : process.env.REACT_APP_INVENTORY_JS_BUNDLE;
const inventoryElementId = 'inventory';

const loadInventory = async () => {
  return setupMicroApp(inventoryUrl, inventoryElementId);
};

const application = {
  update: () => Promise.resolve(), //update function
  bootstrap: () => Promise.resolve(), //bootstrap function
  mount: () => Promise.resolve(loadInventory()), //mount function
  unmount: () => Promise.resolve(handleMicroAppUnmount()) //unmount function
};

export const registerInventoryApp = () => {
  singleSpa.registerApplication('inventory', application, location => location.pathname.startsWith('/inventory'));
};
