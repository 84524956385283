import React, { useCallback, useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import { Tooltip } from '@mui/material';
import { Text, useMpharmaI18n } from 'mpharma-i18n';
import TextInput from 'mpharma-components-library/lib/TextInput';
import Button from 'mpharma-components-library/lib/Button';
import CornerImg from '../../images/corner.png';
import GoodHealth from '../../images/good_health.png';
import MPharma from '../../images/new_logo.png';
import WifiX from '../../images/wifi_x.svg';
import ErrorCircle from '../../images/error_circle.svg';
import { ReactComponent as ContactAdmin } from '../../images/contact_admin.svg';
import { getFacilityUsersFromDB, updateOfflineLoginCount } from '../../db/utils';
import { useLocalStorage } from 'react-use';
import { setCookie } from '../../helpers/utils';
import { isPinValid } from '../../helpers/pin/crack';

import CustomSelect from './CustomOfflineDropdown';
import { AUTH_URL } from '../../helpers/constants';

const PREFIX = 'OfflineLoginScreen';

const classes = {
  container: `${PREFIX}-container`,
  formContainer: `${PREFIX}-formContainer`,
  logoutText: `${PREFIX}-logoutText`,
  link: `${PREFIX}-link`,
  verificationErrorContainer: `${PREFIX}-verificationErrorContainer`,
  noInternetIconTextContainer: `${PREFIX}-noInternetIconTextContainer`,
  btnOkay: `${PREFIX}-btnOkay`,
  htmlTooltip: `${PREFIX}-htmlTooltip`
};

const Root = styled('div')(() => ({
  [`& .${classes.container}`]: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    height: '100vh',
    position: 'fixed',
    top: '0',
    left: '0',
    right: '0',
    bottom: '0',
    zIndex: '10',
    backgroundColor: '#ffffff'
  },

  [`& .${classes.formContainer}`]: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1
  },

  [`& .${classes.logoutText}`]: {
    paddingTop: 10,
    color: 'rgba(0, 0, 0, 0.89)',
    fontFamily: 'Sofia Pro',
    fontSize: 30
  },

  [`& .${classes.link}`]: {
    fontSize: '14px',
    color: '#8b97a4',
    margin: '0px',
    padding: '0px',
    textDecoration: 'underline'
  },

  [`& .${classes.verificationErrorContainer}`]: {
    width: 'inherit',
    display: 'grid',
    gridTemplateAreas: `'img header header header'
                        '. msg msg msg'`,
    padding: '16px',
    border: '1px solid #ebcccc',
    borderRadius: '8px',
    backgroundColor: '#f2dede',
    marginBottom: '24px'
  },

  [`& .${classes.noInternetIconTextContainer}`]: {
    height: '40px',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#fff9e6',
    fontSize: '14px',
    color: '#b6291f',
    flexDirection: 'row'
  },

  [`& .${classes.btnOkay}`]: {
    padding: '8px 16px',
    border: '1px solid #384853',
    borderRadius: '4px',
    height: '40px',
    width: '210px',
    backgroundColor: '#ffffff',
    color: '#384853',
    marginTop: '12px',
    fontSize: '13px',
    fontWeight: '800',
    cursor: 'pointer'
  },

  [`& .${classes.htmlTooltip}`]: {
    background: '#000000',
    color: '#ffffff',
    padding: '1.5em'
  }
}));

const OfflineLoginScreen = ({ history, online }) => {
  const { translateKey } = useMpharmaI18n();

  const [selected, setSelected] = useState({});
  const [hasError, setHasError] = useState(false);
  const [hidePin, toggleHidePin] = useState(true);
  const [openContactModal, setOpenContactModal] = useState(false);
  const [pin, setPin] = useState('');
  const [users, setUsers] = useState([]);
  const [user, setUser] = useLocalStorage('bloom:user');
  const [facility] = useLocalStorage('bloom:facility');

  const [, setIsOfflineLoggedOut] = useLocalStorage('bloom:offlineLoggedOut');

  const buttonDisabled = () => (selected && selected.user_pin === null) || pin.length < 4;
  const getSelectedUserFacility = useCallback(
    () => selected && facility && facility.entities.facilities && facility.entities.facilities[selected.facility_id],
    [selected, facility]
  );

  useEffect(() => {
    const handleBackButton = e => {
      e.preventDefault();
      if (e?.state?.state) {
        if (online) window.location.href = `${AUTH_URL}`;
      }
    };
    window.addEventListener('popstate', handleBackButton);
    return () => {
      window.removeEventListener('popstate', handleBackButton);
    };
  }, [online]);

  useEffect(() => {
    async function getUsers() {
      const localUsers = (await getFacilityUsersFromDB()) || [];
      if (localUsers?.length) {
        setUsers(localUsers);
        if (user?.id) {
          const prevLoggedIn = localUsers.find(p => p.id === user.id);
          setSelected(prevLoggedIn);
        }
      }
    }

    getUsers();
  }, [user]);

  const handleSelect = item => {
    setSelected(item);
    if (hasError) {
      setHasError(false);
    }
  };

  async function verifyOfflineUserPin() {
    const isUser = isPinValid(pin, selected.user_pin);
    const userBelongsToFacility = getSelectedUserFacility();

    if (isUser && userBelongsToFacility) {
      setIsOfflineLoggedOut(false);
      setUser(selected);
      const bloomCookie = localStorage.getItem('bloom:cookie');
      if (bloomCookie && bloomCookie.length > 20) {
        setCookie('idToken', bloomCookie);
      }
      try {
        await updateOfflineLoginCount('success');
      } catch (e) {
        //do nothing
      }
      history.push('/home');
      history.go(0);
    } else {
      setHasError(true);
      try {
        await updateOfflineLoginCount('fail');
      } catch (e) {
        //do nothing
      }
    }
  }

  return (
    <Root>
      <div className={classes.container}>
        <div style={{ position: 'relative', height: '100vh', display: 'flex', flexDirection: 'column' }}>
          <div className={classes.noInternetIconTextContainer}>
            <img src={WifiX} alt="wifi_icon" style={{ height: '20px', width: '20px' }} />

            <Text i18nKey="home.noInternetMessage">You have no internet connection. Login with your Offline PIN</Text>
          </div>

          <div className={classes.formContainer}>
            <div style={{ width: '380px' }}>
              <div style={{ marginBottom: '1.4rem' }}>
                <img style={{ height: '60px', marginLeft: '-24px' }} src={MPharma} alt="mpharma_logo" />
              </div>
              {hasError && (
                <div className={classes.verificationErrorContainer}>
                  <div
                    style={{
                      display: 'grid',
                      gridArea: 'img',
                      height: '30px',
                      width: '30px',
                      alignItems: 'center',
                      justifyContent: 'center'
                    }}
                  >
                    <img src={ErrorCircle} alt="error_icon" />
                  </div>

                  <span
                    style={{
                      display: 'grid',
                      gridArea: 'header',
                      alignItems: 'center',
                      fontWeight: 'bold',
                      fontSize: '14px',
                      color: '#eb5757'
                    }}
                  >
                    <Text i18nKey="home.offlineLoginFailed">Login failed</Text>
                  </span>

                  <span style={{ display: 'grid', gridArea: 'msg', fontSize: '13px', color: '#eb5757' }}>
                    <Text i18nKey="home.offlineLoginFailedMessage">
                      Your username, password didnt match. Please try again
                    </Text>
                  </span>
                </div>
              )}

              <div style={{ display: 'flex', alignItems: 'baseline' }}>
                <h3>
                  <Text i18nKey="home.offlineLoginHeader">Offline login.</Text>
                </h3>
                <span style={{ marginLeft: '8px' }}>
                  <Tooltip
                    classes={{
                      tooltip: classes.htmlTooltip
                    }}
                    title={translateKey('home.whySeeingThisTooltip')}
                    placement="top"
                    arrow
                  >
                    <span className={classes.link}>
                      <Text i18nKey="home.whySeeingThis">Why am I seeing this?</Text>
                    </span>
                  </Tooltip>
                </span>
              </div>
              <div style={{ width: 'inherit', marginTop: '16px' }}>
                <CustomSelect
                  data-testid="offlineUserDropdown"
                  label={<Text i18nKey="home.selectUser">Please select user to login</Text>}
                  value={selected}
                  handleSelect={handleSelect}
                  users={users || []}
                />
              </div>

              <div style={{ width: 'inherit', marginTop: '24px' }}>
                <TextInput
                  autoFocus
                  data-testid={'offlineUserPin'}
                  fullWidth
                  label={translateKey('home.offlineLabel')}
                  name="pin_code"
                  value={pin}
                  defautlValue={pin}
                  onChange={e => {
                    const text = String(e.target.value);
                    if (hasError) {
                      setHasError(false);
                    }
                    if (text.match('^[0-9]*$')) {
                      setPin(text);
                    }
                  }}
                  type="password"
                  hidden={hidePin}
                  toggleVisibility={toggleHidePin}
                  placeholder={translateKey('home.enterPinPlaceholder')}
                  role="textbox"
                  maxLength={4}
                />
              </div>

              <button
                onClick={() => {
                  setOpenContactModal(true);
                }}
                style={{ background: 'none', border: 'none', cursor: 'pointer' }}
                className={classes.link}
              >
                <Text i18nKey="home.forgotOfflinePin">Forgot Offline PIN?</Text>
              </button>

              <Button
                data-testid={'offlinePinVerifyBtn'}
                onClick={verifyOfflineUserPin}
                disabled={buttonDisabled()}
                style={{
                  marginTop: '24px',
                  width: '100%',
                  borderRadius: '6px',
                  color: buttonDisabled() && '#8f9ba7',
                  backgroundColor: buttonDisabled() && '#e3e6ea'
                }}
                variant={'primary'}
                text={<Text i18nKey="home.signin">Sign In</Text>}
              />
            </div>
          </div>

          <img
            alt="corner_img"
            src={CornerImg}
            style={{
              position: 'absolute',
              height: '240px',
              bottom: '0',
              left: '0'
            }}
          />
        </div>

        <img
          src={GoodHealth}
          alt="good_health_img"
          style={{
            width: '100%',
            objectPosition: 'bottom',
            height: '100vh',
            overflow: 'hidden'
          }}
        />
      </div>
      {openContactModal ? (
        <div
          style={{
            position: 'absolute',
            top: '0',
            left: '0',
            bottom: '0',
            right: '0',
            backgroundColor: '#1C22244a',
            zIndex: '11100',
            display: 'grid',
            placeContent: 'center'
          }}
        >
          <div
            style={{
              width: '438px',
              height: '322px',
              boxShadow: '0px 26px 64px rgba(0, 0, 0, 0.07)',
              background: '#ffffff',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <ContactAdmin />
            <h4
              style={{
                fontSize: '24px',
                color: '#262626',
                fontWeight: '800',
                lineHeight: '32px'
              }}
            >
              <Text i18nKey="home.contactUs">Contact Us</Text>
            </h4>
            <p
              style={{
                width: '320px',
                textAlign: 'center',
                fontSize: '14px',
                lineHeight: '20px',
                margin: '1rem 0',
                color: '#262626',
                fontWeight: '400'
              }}
            >
              <Text i18nKey="home.contactAssistance">Please contact mPharma support for assistance. </Text>
            </p>

            <button
              onClick={() => {
                setOpenContactModal(false);
                if (online) {
                  window.location.href = `${AUTH_URL}`;
                }
              }}
              className={classes.btnOkay}
            >
              Okay
            </button>
          </div>
        </div>
      ) : null}
    </Root>
  );
};

export default OfflineLoginScreen;
