import { useEffect, useState } from 'react';

export function useAppMountStatus() {
  const [appLoading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  function handleMounting(e) {
    setLoading(e.detail.loading);
    setErrorMessage(e.detail.error);
  }

  useEffect(() => {
    window.addEventListener('loading', handleMounting);

    return () => {
      window.removeEventListener('loading', handleMounting);
    };
  }, []);

  return {
    appLoading,
    errorMessage
  };
}

export default useAppMountStatus;
