import { useState } from 'react';
import { BroadcastChannel as BroadcastChannelBackup } from 'broadcast-channel';
import { BROADCAST_CHANNEL_NAMES } from '../helpers/constants';

function useUploadProgressBroadcastChannel() {
  const [priceUploadProgress, setPriceUploadProgress] = useState(0);
  const [priceErrorCount, setPriceErrorCount] = useState(0);
  const [pricePageSwitched, setPricePageSwitched] = useState(false);
  const [priceUploadProcessingStatus, setPriceUploadProcessingStatus] = useState('');
  const [priceUploadCountry, setPriceUploadCountry] = useState('');
  const [priceUploadFacilityId, setPriceUploadFacilityId] = useState('');
  const [isBulkPriceUpload, setIsBulkPriceUpload] = useState();
  const [priceUploadProductImportId, setPriceUploadProductImportId] = useState();
  const [totalRecords, setTotalRecords] = useState(0);
  const [priceUploadErrorMessage, setPriceUploadErrorMessage] = useState('');

  const BloomBroadcastChannel = typeof BroadcastChannel === 'undefined' ? BroadcastChannelBackup : BroadcastChannel;
  const uploadProgressChannel = new BloomBroadcastChannel(BROADCAST_CHANNEL_NAMES.PRICE_UPLOAD_PROGRESS_CHANNEL);

  uploadProgressChannel.onmessage = e => {
    const {
      uploadPercentage,
      errorCount,
      pageSwitched,
      processingStatus,
      country,
      uploadFacilityId,
      isBulkUpload,
      productImportId,
      numberOfRecords,
      errorMessage
    } = e.data;

    if (uploadPercentage !== priceUploadProgress) {
      setPriceUploadProgress(uploadPercentage);
    }
    if (errorCount !== priceErrorCount) {
      setPriceErrorCount(errorCount);
    }
    if (pageSwitched !== pricePageSwitched) {
      setPricePageSwitched(pageSwitched);
    }
    if (processingStatus) {
      setPriceUploadProcessingStatus(processingStatus);
    }
    if (country && country !== 'undefined') {
      setPriceUploadCountry(country);
    }

    if (uploadFacilityId) {
      setPriceUploadFacilityId(uploadFacilityId);
    }
    setIsBulkPriceUpload(isBulkUpload);

    setPriceUploadProductImportId(productImportId);

    if (errorMessage && errorMessage !== 'undefined') {
      setPriceUploadErrorMessage(errorMessage);
    }

    if (numberOfRecords) {
      setTotalRecords(numberOfRecords);
    }
  };

  return {
    priceUploadProgress,
    setPriceUploadProgress,
    priceErrorCount,
    setPriceErrorCount,
    pricePageSwitched,
    setPricePageSwitched,
    priceUploadProcessingStatus,
    setPriceUploadProcessingStatus,
    uploadProgressChannel,
    priceUploadCountry,
    totalUploadRecords: totalRecords,
    priceUploadFacilityId,
    setPriceUploadFacilityId,
    isBulkPriceUpload,
    priceUploadProductImportId,
    priceUploadErrorMessage
  };
}

export default useUploadProgressBroadcastChannel;
