import { useState } from 'react';
import { BroadcastChannel as BroadcastChannelBackup } from 'broadcast-channel';

function useLostSalesCountChannel() {
  const [unsyncedLostSaleCount, setUnsyncedLostSaleCount] = useState('');
  const BloomBroadcastChannel = typeof BroadcastChannel === 'undefined' ? BroadcastChannelBackup : BroadcastChannel;

  const unsyncedLostSaleCountChannel = new BloomBroadcastChannel('lost-sales-count-channel');

  unsyncedLostSaleCountChannel.onmessage = e => {
    const { lostSalesCount } = e.data;

    if (lostSalesCount > 0 && lostSalesCount !== unsyncedLostSaleCount) {
      setUnsyncedLostSaleCount(lostSalesCount);
    }
    unsyncedLostSaleCountChannel.close();
  };

  return [unsyncedLostSaleCount, setUnsyncedLostSaleCount];
}

export default useLostSalesCountChannel;
