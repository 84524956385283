import pbkdf2 from './pbkdf2';

/**
 *
 * @param {user pin entered} pin
 * @param {already hashed pin for user} hash
 * @returns {boolean value if pin matches}
 */
let validatePassword = function(pin, hash) {
  let parts = hash.split('$');
  let iterations = parts[1];
  let salt = parts[2];
  return pbkdf2(pin, Buffer.from(salt), iterations, 32).toString('base64') === parts[3];
};

export const isPinValid = (pin, hash) => validatePassword(pin, hash);
