import React from 'react';
import styled from 'styled-components';
import Button from 'mpharma-components-library/lib/Button';
import * as Sentry from '@sentry/react';
import { ReactComponent as ErrorIcon } from '../../images/error-sign.svg';
import { Text } from 'mpharma-i18n';

class ErrorBoundary extends React.Component {
  state = {
    hasError: false,
    error: {}
  };

  static getDerivedStateFromError(error) {
    console.log('************************************');
    console.log('An error occurred');
    console.log(error);
    console.log('End of error');
    console.log('************************************');
    Sentry.captureException(error);
    return { hasError: true, error };
  }

  onRefreshPage = () => {
    if (this.props.onTryAgain) {
      return this.props.onTryAgain();
    }
    this.setState({ hasError: false });
  };

  render() {
    const { hasError } = this.state;
    const { message, children, error: showError, actionText } = this.props;
    if (hasError || showError) {
      return (
        <Container>
          <div>
            <ErrorIcon />
            <ErrorState>
              <Text i18nKey="error.stateText">Error</Text>
            </ErrorState>
            <ErrorText>
              <Text i18nKey={message ? message : 'error.message'}>
                {message ? message : 'Sorry something bad happened, Please refresh'}
              </Text>
            </ErrorText>
            <Button
              style={{ marginTop: 12 }}
              onClick={this.onRefreshPage}
              variant="secondary"
              text={<Text i18nKey={actionText || 'error.tryAgain'} />}
            />
          </div>
        </Container>
      );
    }

    return children;
  }
}

ErrorBoundary.defaultProps = {
  action: null
};
const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 100vh;
`;

const ErrorState = styled.p`
  color: rgba(0, 0, 0, 0.89);
  font-family: 'Sofia Pro';
  font-size: 34px;
  line-height: 60px;
`;

const ErrorText = styled.p`
  color: #959595;
  font-family: 'Sofia Pro';
  font-size: 14px;
  line-height: 18px;
`;

export default ErrorBoundary;
