import { openDB } from 'idb';

const dbVersion = 4;

export function setupInitialVersion(db) {
  if (!db.objectStoreNames.contains('inventory')) {
    console.log('making the inventory objectstore');
    db.createObjectStore('inventory', { keyPath: 'uuid' });
  }

  // new db for facility users.
  if (!db.objectStoreNames.contains('facility_users')) {
    console.log('making the facility users objectstore');
    db.createObjectStore('facility_users', { keyPath: 'id' });
  }

  if (!db.objectStoreNames.contains('offline_login_count')) {
    console.log('making the offline_login_count  objectstore');
    db.createObjectStore('offline_login_count', { keyPath: 'date' });
  }

  if (!db.objectStoreNames.contains('errors')) {
    console.log('making the error objectstore');
    db.createObjectStore('errors');
  }

  if (!db.objectStoreNames.contains('progress')) {
    console.log('making the progress objectstore');
    db.createObjectStore('progress');
  }

  if (!db.objectStoreNames.contains('sales')) {
    console.log('making the sales objectstore');
    const salesStore = db.createObjectStore('sales', { keyPath: ['uuid', 'patient_id'] });
    salesStore.createIndex('sale_id', 'uuid');
    salesStore.createIndex('patientId', 'patient_id');
  }
  if (!db.objectStoreNames.contains('patients')) {
    console.log('making the patients objectstore');
    db.createObjectStore('patients', {
      keyPath: 'patient_pk'
    });
  }
  if (!db.objectStoreNames.contains('lost_sales')) {
    console.log('making the lost_sales objectstore');
    db.createObjectStore('lost_sales', { keyPath: 'uuid' });
  }
  if (!db.objectStoreNames.contains('expiring_soon')) {
    console.log('making the expiring_soon objectstore');
    db.createObjectStore('expiring_soon', { keyPath: 'uuid' });
  }
  if (!db.objectStoreNames.contains('expired_products')) {
    console.log('making the expired_products objectstore');
    db.createObjectStore('expired_products', { keyPath: 'uuid' });
  }
  if (!db.objectStoreNames.contains('new_prices')) {
    console.log('making the new prices objectstore');
    db.createObjectStore('new_prices', { keyPath: 'uuid' });
  }
  if (!db.objectStoreNames.contains('batches')) {
    console.log('making the batches objectstore');
    db.createObjectStore('batches', { keyPath: 'uuid' });
  }
  // new db for facility promos.
  if (!db.objectStoreNames.contains('promos')) {
    console.log('making the promos objectstore');
    db.createObjectStore('promos', { keyPath: 'id' });
  }
}

export function setupVersionOne(db) {
  if (db.objectStoreNames.contains('errors')) {
    console.log('old version; deleting the errors table');
    db.deleteObjectStore('errors');
  }
}

class BloomDatabase {
  constructor(dbName = 'bloom', version = dbVersion) {
    this.dbName = dbName;
    this.version = version;
  }

  createDB() {
    return openDB(this.dbName, this.version, {
      upgrade(db, oldVersion, newVersion, transaction) {
        // upgrade function only happens when the version is changed
        switch (oldVersion) {
          // NB: oldVersion is {this.version - 1} not the current version
          case 0:
            // initial version
            setupInitialVersion(db);
            break;
          case 1:
            // delete error table
            setupVersionOne(db);
            break;
          default:
            break;
        }
      },
      blocked() {
        console.log("this version is blocked cos there's an already existing db.");
      }
    });
  }

  db() {
    this.promise = this.promise || openDB(this.dbName, this.version);
    return this.promise;
  }

  saveProducts(data, facilityId) {
    this.db()
      .then(db => {
        const tx = db.transaction('inventory', 'readwrite');
        const inventoryStore = tx.objectStore('inventory');
        for (let i = 0, len = data.length; i < len; i++) {
          inventoryStore.add(data[i]);
        }
        return tx;
      })
      .then(tx => {
        console.log('data saved in idb');
        return tx.complete;
      })
      .catch(err => console.error('trouble when saving inventory to idb', err));
  }

  getProducts() {
    return this.db()
      .then(db => {
        const tx = db.transaction('inventory', 'readonly');
        const productStore = tx.objectStore('inventory');
        return productStore.getAll();
      })
      .catch(err => console.error('trouble getting inventory from idb', err));
  }
}

const bloomDatabase = new BloomDatabase();

export default bloomDatabase;
