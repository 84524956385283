import React from 'react';
import { styled } from '@mui/material/styles';
const PREFIX = 'SettingsLink';

const classes = {
  root: `${PREFIX}-root`,
  subTitle: `${PREFIX}-subTitle`,
  container: `${PREFIX}-container`
};

const Root = styled('div')(({ theme }) => ({
  [`& .${classes.root}`]: {
    height: '20px',
    cursor: 'pointer',
    color: 'rgba(0,0,0,0.89)',
    fontFamily: 'Sofia Pro',
    fontSize: '16px',
    fontWeight: 'bold',
    letterSpacing: '0.71px',
    padding: '0px',
    lineHeight: '20px'
  },

  [`& .${classes.subTitle}`]: {
    width: '100%',
    color: 'rgba(0,0,0,0.6)',
    fontFamily: 'Sofia Pro',
    fontSize: '14px',
    letterSpacing: '0.63px',
    lineHeight: '19px'
  },

  [`&.${classes.container}`]: {
    paddingLeft: '24px',
    paddingBottom: '10px',
    paddingTop: '10px',
    width: '415px',
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: '#eaeaea'
    }
  }
}));

export function SettingLink({ title, subTitle, handleClick }) {
  return (
    <Root className={classes.container} onClick={handleClick} data-testid="settings-link">
      <p className={classes.root}>{title}</p>
      {subTitle && <p className={classes.subTitle}>{subTitle}</p>}
    </Root>
  );
}

export default SettingLink;
