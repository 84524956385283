import * as singleSpa from 'single-spa';
import { handleMicroAppUnmount, setupMicroApp } from './utils';

const posUrl =
  process.env.NODE_ENV === 'development'
    ? 'http://localhost:4001/static/js/main.js'
    : process.env.REACT_APP_POS_JS_BUNDLE;

const posElementId = 'pos';

const loadPosApp = async () => {
  return setupMicroApp(posUrl, posElementId);
};

const application = {
  update: () => Promise.resolve(), //update function
  bootstrap: () => Promise.resolve(), //bootstrap function
  mount: () => Promise.resolve(loadPosApp()), //mount function
  unmount: () => Promise.resolve(handleMicroAppUnmount()) //unmount function
};

export const registerPosApp = () => {
  singleSpa.registerApplication('pos', application, location => location.pathname.startsWith('/pos'));
};
