import Dexie from 'dexie';
import { chain, values, last, isEmpty } from 'lodash';
import moment from 'moment';
import {
  DATABASE_NAMES,
  EXPIRING_SOON_MAX_NUMBER_OF_DAYS,
  EXPIRING_SOON_MIN_NUMBER_OF_DAYS
} from '../helpers/constants';

let db = new Dexie('bloom');

export async function saveAuditedResources(data, resource, key, pageNo, pageSize) {
  const sprucedUpAuditData = values(
    chain(data.results)
      .groupBy(audit => {
        return audit[`${key}`];
      })
      .value()
  ).map(l => {
    return last(l);
  });
  let dbs = await db.open();
  let resourceTable = dbs.table(resource);
  resourceTable
    .bulkPut(sprucedUpAuditData)
    .then(async function(lastKey) {
      const progress = await calculateDownloadProgress(data.count, data.results.length, pageNo, pageSize);
      const startTime = pageNo === 1 && moment().format('yyyy-MM-DD hh:mm:ss');
      savePercentage(resource, progress, startTime, data.count);
    })
    .catch(Dexie.BulkError, function(e) {
      // Explicitly catching the bulkAdd() operation makes those successful
      // additions commit despite that there were errors.
      console.error(
        `Some  ${resource}  did not succeed. However ${sprucedUpAuditData.length -
          e.failures.length}  ${resource}  was added successfully`
      );
    });
}

export async function calculateDownloadProgress(count, lengthOfCurrentResults, page, pageSize) {
  if (count !== 0) {
    //To handle download percentages never reaching 100 in some cases
    const resourcesFetchedSoFar =
      lengthOfCurrentResults < pageSize ? count : (page - 1) * pageSize + lengthOfCurrentResults;
    const percentage = (resourcesFetchedSoFar * 100) / count;
    return Math.round(percentage);
  }
  return 100;
}

export async function savePercentage(resource, progress, startTime, count) {
  let currentProgress = await getResourceDownloadProgress(resource);
  let dbs = await db.open();
  let progressTable = dbs.table('progress');
  let newProgess = {
    progress,
    error: currentProgress === progress ? currentProgress.error : progress > currentProgress.progress && 0,
    start_time: startTime ? startTime : currentProgress.start_time,
    service_available: true,
    [`empty_${resource}`]: count === 0 && progress === 100
  };
  progressTable.put({ ...newProgess }, `${resource}-download-progress`);
  postMessage({
    resourceDownloadProgress: {
      [`${resource}DownloadProgress`]: newProgess
    }
  });
}

export async function getResourceDownloadProgress(resource) {
  let dbs = await db.open();
  let progressTable = dbs.table('progress');

  return progressTable.get(`${resource}-download-progress`, e => {
    if (e === undefined) {
      return {
        progress: 0,
        error: 0
      };
    }
    return e;
  });
}

export async function getLastAuditItem(resource) {
  let dbs = await db.open();
  let resourceTable = dbs.table(resource);
  const lastResource = await resourceTable.toCollection().last();
  return lastResource && lastResource.id;
}

export async function saveError(resource) {
  let dbs = await db.open();
  db.transaction('rw', db.table('progress'), async () => {
    let progress = await getResourceDownloadProgress(resource);
    const { error } = progress;
    dbs.table('progress').put(
      {
        ...progress,
        error: error >= 0 ? error + 1 : 1,
        service_available: false,
        [`empty_${resource}`]: false
      },
      `${resource}-download-progress`
    );
  })
    .then(result => result)
    .catch(e => {
      console.log(e);
    });
}

export async function getResourceCount(resource) {
  let dbs = await db.open();
  let resourceTable;
  try {
    resourceTable = dbs.table(`${resource}`);
  } catch (error) {}
  return resourceTable?.toCollection()?.count();
}

export async function clearTable(tableName) {
  let dbs = await db.open();
  return dbs.table(tableName).clear();
}

export async function updateInventory(uuid, changes) {
  try {
    let dbs = await db.open();
    const foundProduct = await dbs.table('inventory').get({ uuid });
    const productObject = Object.assign(
      {},
      {
        ...changes,
        oldSellingPrice: foundProduct.walk_in_selling_price,
        oldMuttiPrice: foundProduct.mutti_selling_price,
        hasNewPrice: true
      }
    );
    await dbs.table('inventory').update(uuid, productObject);
  } catch (error) {
    console.log(error);
  }
}

export const updateInventoryQuantities = async (uuid, changes) => {
  try {
    db.table('inventory').update(uuid, {
      ...changes
    });
  } catch (error) {
    console.log(error);
  }
};

export async function updateFacilityUser(id, user) {
  try {
    let dbs = await db.open();
    const foundUser = await dbs.table('facility_users').get({ id });

    if (foundUser) {
      const updateUser = Object.assign(
        {},
        {
          ...foundUser,
          ...user
        }
      );
      await dbs.table('facility_users').put(updateUser, id);
    } else {
      await dbs.table('facility_users').add(user, id);
    }
  } catch (e) {
    console.log(e);
  }
}

export async function updateOfflineLoginCount(state) {
  try {
    let dbs = await db.open();
    const table = dbs.table('offline_login_count');
    const date = moment().format(moment.HTML5_FMT.DATE);
    let count = (await table.get({ date })) || { date, success: 0, fail: 0 };
    count[`${state}`] = count[`${state}`] + 1;
    await table.put(count, date);
  } catch (e) {
    console.log(e);
  }
}

export async function getOfflineLoginAttempt() {
  try {
    let dbs = await db.open();
    return dbs.table('offline_login_count').toArray();
  } catch (e) {
    return [];
  }
}

export async function clearOfflineLoginCount() {
  try {
    let dbs = await db.open();
    return dbs.table('offline_login_count').clear();
  } catch (e) {
    //do nothing
  }
}

export async function getFacilityUsersFromDB() {
  try {
    let dbs = await db.open();
    return await dbs.table('facility_users').toArray();
  } catch (e) {
    console.log(e);
  }
}

export async function databaseQueryForProgressTable(service) {
  let dbs = await db.open();
  return dbs.table('progress').get(`${service}-download-progress`);
}

export async function saveNewPrices(data) {
  try {
    let dbs = await db.open();
    let resourceTable = dbs.table('new_prices');
    await resourceTable.bulkPut(data);
  } catch (error) {
    throw error;
  }
}

export function filterExpiredProducts(products) {
  return {
    results: products.results.filter(item => new Date(item.batch.expiry_date) <= new Date()),
    count: products.count
  };
}

export function filterExpiringSoonProducts(products) {
  return {
    results: products.results.filter(item => {
      const diffTime = new Date(item.batch.expiry_date) - new Date();

      if (diffTime > 0) {
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        return EXPIRING_SOON_MAX_NUMBER_OF_DAYS >= diffDays >= EXPIRING_SOON_MIN_NUMBER_OF_DAYS;
      }
      return false;
    }),
    count: products.count
  };
}

export async function resetNewPriceTag() {
  try {
    let dbs = await db.open();
    const products = await dbs.table('inventory').toArray();
    const updatedProducts = products.map(prod => ({ ...prod, hasNewPrice: false }));
    await dbs.table('inventory').bulkPut(updatedProducts);
  } catch (error) {
    console.log(error);
  }
}

export async function isDatabaseTablePopulated(database, tableName) {
  const db = new Dexie(`${database}`);
  const databaseExists = await Dexie.exists(`${database}`);
  if (databaseExists) {
    let dbs = await db.open();
    const tablesRowCounts = await dbs.table(tableName).toArray();
    db.close();
    return !isEmpty(tablesRowCounts);
  }
  return false;
}

export const clearCacheData = async () => {
  try {
    const cacheNames = await caches.keys();
    await Promise.all(
      cacheNames.map(async name => {
        await caches.delete(name);
      })
    );
  } catch (error) {
    console.error('Error clearing cache:', error.message);
  }
};

export const deleteAllIndexedDBDatabasesExceptOutbox = async (handleLoading, callback) => {
  try {
    const dbs = await window.indexedDB.databases();

    await Promise.all(
      dbs.map(async db => {
        if (db.name !== DATABASE_NAMES.OUTBOX) {
          try {
            await new Promise((resolve, reject) => {
              const request = window.indexedDB.deleteDatabase(db.name);

              request.onsuccess = () => resolve();
              request.onerror = e => reject(e);
            });
          } catch (e) {
            console.error(e);
          }
        }
      })
    ).finally(() => {
      callback();
    });
  } catch (e) {
    console.error(e);
    handleLoading();
  }
};
