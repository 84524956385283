import * as singleSpa from 'single-spa';
import { handleMicroAppUnmount, setupMicroApp } from './utils';

const healthServicesUrl =
  process.env.NODE_ENV === 'development'
    ? 'http://localhost:4005/static/js/main.js'
    : process.env.REACT_APP_HEALTH_SERVICES_JS_BUNDLE;
const healthServiceElementId = 'health-services';

const loadHealthServices = async () => {
  return setupMicroApp(healthServicesUrl, healthServiceElementId);
};

const application = {
  update: () => Promise.resolve(), //update function
  bootstrap: () => Promise.resolve(), //bootstrap function
  mount: () => Promise.resolve(loadHealthServices()), //mount function
  unmount: () => Promise.resolve(handleMicroAppUnmount()) //unmount function
};

export const registerHealthServicesApp = () => {
  singleSpa.registerApplication('health-services', application, location => {
    return location.pathname.startsWith('/health-services');
  });
};
