import * as singleSpa from 'single-spa';
import { handleMicroAppUnmount, setupMicroApp } from './utils';

const labTestUrl =
  process.env.NODE_ENV === 'development'
    ? 'http://localhost:3005/static/js/main.js'
    : process.env.REACT_APP_LAB_TEST_JS_BUNDLE;
const labTestElementId = 'lab-test';

const loadLabTest = async () => {
  return setupMicroApp(labTestUrl, labTestElementId);
};

const application = {
  update: () => Promise.resolve(), //update function
  bootstrap: () => Promise.resolve(), //bootstrap function
  mount: () => Promise.resolve(loadLabTest()), //mount function
  unmount: () => Promise.resolve(handleMicroAppUnmount()) //unmount function
};

export const registerLabTestApp = () => {
  singleSpa.registerApplication('lab-test', application, location => {
    return location.pathname.startsWith('/lab-test');
  });
};
