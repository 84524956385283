import { useState } from 'react';
import { BroadcastChannel as BroadcastChannelBackup } from 'broadcast-channel';

function useSalesHistoryDownloadsBroadcastChannel(user) {
  const [localSalesHistoryObjectId, setSalesHistoryObjectId] = useState('');
  const BloomBroadcastChannel = typeof BroadcastChannel === 'undefined' ? BroadcastChannelBackup : BroadcastChannel;

  const salesHistoryDownloadChannel = new BloomBroadcastChannel('sales-history-download-channel');

  salesHistoryDownloadChannel.onmessage = e => {
    const { salesHistoryObjectId } = e.data;
    if (salesHistoryObjectId !== localSalesHistoryObjectId) {
      setSalesHistoryObjectId(salesHistoryObjectId);
    }
    salesHistoryDownloadChannel.close();
  };

  return [localSalesHistoryObjectId, setSalesHistoryObjectId];
}

export default useSalesHistoryDownloadsBroadcastChannel;
