import React from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import PersonPinIcon from '@mui/icons-material/PersonPin';

const PREFIX = 'UserInfo';

const classes = {
  container: `${PREFIX}-container`,
  icon: `${PREFIX}-icon`,
  name: `${PREFIX}-name`,
  hospital: `${PREFIX}-hospital`
};

const Root = styled('div')(({ theme }) => ({
  [`&.${classes.container}`]: {
    marginLeft: '24px'
  },

  [`& .${classes.icon}`]: {
    fill: 'red',
    height: '35.83px',
    width: '35.83px',
    backgroundColor: '#FFFFFF'
  },

  [`& .${classes.name}`]: {
    height: '21px',
    width: '131px',
    color: '#000000',
    fontFamily: 'Sofia Pro',
    fontSize: '16px',
    lineHeight: '21px'
  },

  [`& .${classes.hospital}`]: {
    height: '21px',
    width: '112px',
    color: '#959595',
    fontFamily: 'Sofia Pro',
    fontSize: '16px',
    lineHeight: '21px',
    marginBottom: '16px'
  }
}));

export function UserInfo({ name, hospital }) {
  return (
    <Root className={classes.container} data-testid="user-info">
      <PersonPinIcon className={classes.icon} />
      <p className={classes.name}>{name}</p>
      <p className={classes.hospital}>{hospital}</p>
    </Root>
  );
}

UserInfo.propTypes = {
  name: PropTypes.string.isRequired,
  hospital: PropTypes.string.isRequired
};

export default UserInfo;
