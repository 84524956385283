/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { isDatabaseTablePopulated } from '../../db/utils';
import { DATABASE_NAMES } from '../../helpers/constants';
import { useIntercom } from 'react-use-intercom';
import { Text } from 'mpharma-i18n';
import Notification from './Notification';
import { ReactComponent as Icondone } from '../../images/Icondone.svg';
import { ReactComponent as Iconwarning } from '../../images/Iconwarning.svg';
import { ReactComponent as Restore } from '../../images/Restore.svg';
import { ReactComponent as WifiX } from '../../images/WifiX.svg';

import useSalesSyncStatusBroadcastChannel from '../../hooks/useSalesSyncStatusBroadcastChannel';
import useOfflineStatus from '../../hooks/useOfflineStatus';
import ProgressBar from './ProgressBar';
import styled from 'styled-components';
import { useLocalStorage } from 'react-use';

const StyledButton = styled.button`
  text-decoration: underline;
  font-size: 14px;
  cursor: pointer;
  border: none;
  background: none;
  padding: 0;
  color: ${props => props.color || '#000'};
`;

const SyncDataNotifications = ({ history, setShowNotificationDrawer }) => {
  const {
    salesSyncInProgress,
    salesSyncCompleted,
    outboxReport,
    salesSyncProgress
  } = useSalesSyncStatusBroadcastChannel();

  const [openSyncStart, setOpenSyncStart] = useState(salesSyncInProgress);
  const [openSuccessAlert, setOpenSuccessAlert] = useState(false);
  const [openFailureAlert, setOpenFailureAlert] = useState(false);
  const [openNoNetworkAlert, setOpenNoNetworkAlert] = useState(false);
  const [openPartialSyncAlert, setOpenPartialSyncAlert] = useState(false);

  const { online } = useOfflineStatus();
  const { show } = useIntercom();
  const [user] = useLocalStorage('bloom:user');

  const showNotification =
    openSyncStart || openSuccessAlert || openFailureAlert || openNoNetworkAlert || openPartialSyncAlert;

  const handleSyncRetry = () => {
    setOpenPartialSyncAlert(false);
    //navigate to pos sales history page or if in pos sales history reload
    if (window.location.pathname === '/pos/sales') {
      window.location.reload();
    } else {
      history.push('/pos/sales');
    }
  };

  const handleContactSupport = () => {
    show();
    setOpenFailureAlert(false);
  };

  const notificationMessage = () => {
    if (openSyncStart) {
      return (
        <>
          <b style={{ fontFamily: 'Sofia Pro Bold' }}>
            <Text i18nKey="notifications.sync.restoringTitle">Restoring un-synced data.</Text>
          </b>
          <br />
          <Text i18nKey="notifications.sync.restoringMessage">
            Bloom is currently restoring data. Data may be unavailable. This will take few seconds time.
          </Text>
          <ProgressBar style={{ maxWidth: '30%' }} percentage={salesSyncProgress} />
          <div style={{ display: 'flex', flexDirection: 'row', gap: '2rem', marginTop: '0.5rem' }}>
            <StyledButton color="#587cc8" onClick={() => setShowNotificationDrawer(true)}>
              <Text i18nKey="common.inprogress.fileUploadProcessing.viewProgress"> View progress </Text>
            </StyledButton>
            <StyledButton color="#eb5757" onClick={() => setOpenSyncStart(false)}>
              <Text i18nKey="common.cancel"> Cancel </Text>
            </StyledButton>
          </div>
        </>
      );
    } else if (openSuccessAlert) {
      return (
        <>
          <b style={{ fontFamily: 'Sofia Pro Bold' }}>
            🎉<Text i18nKey="notifications.sync.completeTitle">Data sync complete. Your data is now up to date.</Text>
          </b>
          <br />
          <Text i18nKey="notifications.sync.completeMessage">Bloom has successfully synced all un-synced data.</Text>
        </>
      );
    } else if (openFailureAlert) {
      return (
        <>
          <b style={{ fontFamily: 'Sofia Pro Bold' }}>
            <Text i18nKey="notifications.sync.failedTitle">Failed to sync un-sync data.</Text>
          </b>
          <br />
          <Text i18nKey="notifications.sync.failedMessage">An error occurred while attempting to sync sales.</Text>
          <div>
            <StyledButton color="#587cc8" onClick={handleContactSupport}>
              <Text i18nKey="notifications.sync.failedAction">Contact support</Text>{' '}
            </StyledButton>
          </div>
        </>
      );
    } else if (openNoNetworkAlert) {
      return (
        <>
          <b style={{ fontFamily: 'Sofia Pro Bold' }}>
            <Text i18nKey="notifications.sync.noNetworkTitle">Connect to internet to sync data.</Text>
          </b>
          <br />
          <Text i18nKey="notifications.sync.noNetworkMessage">Connect to the internet to sync unsaved data.</Text>
        </>
      );
    } else if (openPartialSyncAlert) {
      return (
        <>
          <b style={{ fontFamily: 'Sofia Pro Bold' }}>
            <Text i18nKey="notifications.sync.partialSyncTitle">Bloom is currently syncing your data.</Text>
          </b>
          <br />
          <Text i18nKey="notifications.sync.partialSyncMessage">Some data has failed to sync. Please retry.</Text>
          <div style={{ display: 'flex', flexDirection: 'row', gap: '2rem' }}>
            <StyledButton color="#587cc8" onClick={handleSyncRetry}>
              <Text i18nKey="notifications.sync.retrySync">Retry sync</Text>
            </StyledButton>
            <StyledButton color="#eb5757" onClick={() => setOpenPartialSyncAlert(false)}>
              <Text i18nKey="common.cancel"> Cancel </Text>
            </StyledButton>
          </div>
        </>
      );
    }
  };

  const closeCallback = () => {
    if (openSyncStart) {
      setOpenSyncStart(false);
    } else if (openSuccessAlert) {
      setOpenSuccessAlert(false);
    } else if (openFailureAlert) {
      setOpenFailureAlert(false);
    } else if (openNoNetworkAlert) {
      setOpenNoNetworkAlert(false);
    } else if (openPartialSyncAlert) {
      setOpenPartialSyncAlert(false);
    }
  };

  const handleDataSyncNotifications = () => {
    if (outboxReport?.done) {
      const { successes, failures, total } = outboxReport;
      if (total !== 0) {
        if (successes === total && failures === 0) {
          setOpenSuccessAlert(true);
        } else if (successes === 0 && failures === total) {
          setOpenFailureAlert(true);
        } else if (successes !== total && failures !== total) {
          setOpenPartialSyncAlert(true);
        }
      }
    }
  };

  const showIcon = () => {
    if (openSyncStart || openPartialSyncAlert) {
      return <Restore style={{ margin: 2 }} />;
    } else if (openSuccessAlert) {
      return <Icondone style={{ margin: 2 }} />;
    } else if (openFailureAlert) {
      return <Iconwarning style={{ margin: 2 }} />;
    } else if (openNoNetworkAlert) {
      return <WifiX style={{ margin: 2 }} />;
    }
  };

  useEffect(() => {
    if (salesSyncCompleted) {
      handleDataSyncNotifications();
    }

    setOpenSyncStart(salesSyncInProgress);
  }, [salesSyncInProgress, salesSyncCompleted, outboxReport?.done]);

  useEffect(() => {
    if (!online) {
      (async () => {
        const outboxSalesPresent = await isDatabaseTablePopulated(DATABASE_NAMES.OUTBOX, 'outbox');
        if (outboxSalesPresent && !user?.is_staff) {
          setOpenNoNetworkAlert(true);
        }
      })();
    } else {
      setOpenNoNetworkAlert(false);
    }
  }, [online]);

  return (
    <>
      {showNotification && (
        <Notification
          message={notificationMessage()}
          textStyle={{ width: 'unset', lineHeight: '1.7em' }}
          icon={showIcon()}
          success={openSuccessAlert}
          critical={openFailureAlert}
          warning={openSyncStart || openPartialSyncAlert || openNoNetworkAlert}
          handleClose={closeCallback}
        />
      )}
    </>
  );
};

export default SyncDataNotifications;
