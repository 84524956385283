import assert from 'assert';
import { createHmac } from 'crypto';

/**
 *
 * @param {plain text to be computed on} key
 * @param {salt associated with hash} salt
 * @param {number of iterations used in computing the hash} iterations
 * @param {key length} dkLen
 * @returns
 */
function pbkdf2(key, salt, iterations, dkLen) {
  let hLen = 32; //SHA256 Mac length
  assert(dkLen <= (Math.pow(2, 32) - 1) * hLen, 'requested key length too long');
  assert(typeof key == 'string' || Buffer.isBuffer(key), 'key must be a string or buffer');
  assert(typeof salt == 'string' || Buffer.isBuffer(salt), 'key must be a string or buffer');

  if (typeof key == 'string') key = Buffer.from(key);
  if (typeof salt == 'string') salt = Buffer.from(salt);

  let DK = Buffer.alloc(dkLen);
  let T = Buffer.alloc(hLen);
  let block1 = Buffer.alloc(salt.length + 4);

  let l = Math.ceil(dkLen / hLen);
  let r = dkLen - (l - 1) * hLen;

  salt.copy(block1, 0, 0, salt.length);
  for (let i = 1; i <= l; i++) {
    block1.writeUInt32BE(i, salt.length);

    let U = createHmac('sha256', key)
      .update(block1)
      .digest();
    U.copy(T, 0, 0, hLen);

    for (let j = 1; j < iterations; j++) {
      U = createHmac('sha256', key)
        .update(U)
        .digest();

      for (let k = 0; k < hLen; k++) {
        T[k] ^= U[k];
      }
    }

    let destPos = (i - 1) * hLen;
    let len = i === l ? r : hLen;
    T.copy(DK, destPos, 0, len);
  }

  return DK;
}

export default pbkdf2;
