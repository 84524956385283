import React from 'react';
import { styled } from '@mui/material/styles';
import { Button } from 'mpharma-components-library/lib/Button/Button';
import logo from '../../images/new_logo.png';
import { Text, useMpharmaI18n } from 'mpharma-i18n';

const PREFIX = 'LogoutScreen';

const classes = {
  container: `${PREFIX}-container`,
  logoutText: `${PREFIX}-logoutText`
};

const Root = styled('div')({
  [`&.${classes.container}`]: {
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    height: '80vh'
  },
  [`& .${classes.logoutText}`]: {
    paddingTop: 10,
    color: 'rgba(0, 0, 0, 0.89)',
    fontFamily: 'Sofia Pro',
    fontSize: 30
  }
});

export const LogoutScreen = ({ onSignout }) => {
  const { translateKey } = useMpharmaI18n();

  return (
    <Root data-testid="logout-id" className={classes.container}>
      <div>
        <img src={logo} alt="mpharma_logo" />
        <div className={classes.logoutText}>
          <Text i18nKey="home.loggedUserOut">You've been logged out</Text>
        </div>
        <Button style={{ marginTop: 12 }} onClick={onSignout} variant="secondary" text={translateKey('home.signin')} />
      </div>
    </Root>
  );
};

export default LogoutScreen;
