import styled from 'styled-components';

export const FeedbackModalContainer = styled.div`
   {
    padding: 30px;
    h1 {
      font-size: 25px;
      line-height: unset;
      color: #000;
      svg {
        margin: -14px 0px 0px;
        margin-left: auto;
        display: block;
        cursor: pointer;
      }
    }
    h1,
    b {
      font-family: 'Sofia Pro Bold';
      color: #000;
    }

    p {
      font-size: 14px;
      margin: 20px 0px;
      color: #000;
      span {
        color: #6b7b8a;
      }
    }
    hr {
      border: 0;
      border-bottom: 1px solid #d9dfea;
    }
    div.options {
      display: flex;
      margin-bottom: 21px;
      div {
        border: 0.5px solid #384853;
        border-radius: 14px;
        font-size: 12.4px;
        padding: 3px 11px 5px;
        margin-right: 17px !important;
        background: #fff;
        user-select: none;
        cursor: pointer;
        color: #fff;
        background: #ff5100;
        border: 0.5px solid #ff5100;
      }
    }
  }
`;
