import { useState } from 'react';
import { BroadcastChannel as BroadcastChannelBackup } from 'broadcast-channel';

function useInventoryDownloadBroadcastChannel(user) {
  const [localInventoryLevelDownloadObjectId, setLocalInventoryLevelDownloadObjectId] = useState('');
  const BloomBroadcastChannel = typeof BroadcastChannel === 'undefined' ? BroadcastChannelBackup : BroadcastChannel;

  const InventoryDownloadDownloadChannel = new BloomBroadcastChannel('inventory-level-download-channel');

  InventoryDownloadDownloadChannel.onmessage = e => {
    const { inventoryLevelDownloadTrackerId } = e.data;
    if (inventoryLevelDownloadTrackerId !== localInventoryLevelDownloadObjectId) {
      setLocalInventoryLevelDownloadObjectId(inventoryLevelDownloadTrackerId);
    }
    InventoryDownloadDownloadChannel.close();
  };

  return [localInventoryLevelDownloadObjectId, setLocalInventoryLevelDownloadObjectId];
}

export default useInventoryDownloadBroadcastChannel;
