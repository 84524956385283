import React, { useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import { FeedbackModalContainer } from './styles';
import { API_ROOT } from '../../../helpers/constants';
import { patch } from '../../../admin/api';
import { Text } from 'mpharma-i18n';
import { ReactComponent as CloseIcon } from '../../../images/close.svg';
import { useMixpanel } from '../Mixpanel/Provider';
import { useLocalStorage } from 'react-use';

export default function MCAFeedbackDialog({ close, feedback }) {
  const mixpanel = useMixpanel();
  const [user] = useLocalStorage('bloom:user');
  const [facility] = useLocalStorage('bloom:facility');

  useEffect(() => {
    let mounted = true;

    const myfacility = facility && user && facility.entities && facility.entities.facilities[user.facility_id];

    const { email, groups } = user;

    //Run patch to mark as read
    if (mounted && feedback?.id) {
      const BASE_URL = `${API_ROOT}/insights-bff/performance/mca-user-reviews/${feedback.id}/`;

      patch(
        `${BASE_URL}`,
        () => {
          return;
        },
        () => {
          return;
        },
        { is_read: true }
      );
      mixpanel.track('User Views Performance Feedback', {
        email,
        facility_name: myfacility?.name || 'mPharma admin',
        user_role: groups?.toString() || ''
      });
    }
    return () => (mounted = false);
  }, [feedback?.id, facility, user, mixpanel]);
  return (
    <Dialog fullWidth maxWidth="sm" open onClose={close}>
      <FeedbackModalContainer>
        <h1>
          <Text i18nKey="notifications.MCAFeedback.modal.title">Feedback</Text>
          <CloseIcon onClick={close} />
        </h1>

        <p style={{ fontSize: 13 }}>
          <Text i18nKey="notifications.MCAFeedback.modal.from">From</Text>{' '}
          <b style={{ padding: '0 15px' }}>
            {feedback?.reviewer?.first_name} {feedback?.reviewer?.last_name}
          </b>{' '}
          <span>{new Date(feedback?.created_at)?.toDateString()}</span>
        </p>
        <hr />
        <p>{feedback?.note}</p>
        <p>
          <b>
            <Text i18nKey="notifications.MCAFeedback.modal.description">
              Some tags to help you understand your review
            </Text>
          </b>
        </p>
        <div className="options">
          {feedback?.reasons.map(reason => (
            <div key={reason}>{reason}</div>
          ))}
        </div>
      </FeedbackModalContainer>
    </Dialog>
  );
}
