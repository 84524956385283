import Dexie from 'dexie';

/**
 * Get IndexedDB table count
 * @param {string} database
 * @param {string} table
 * @returns {number} count
 */
export const getTableCount = async (database, table) => {
  const databaseExists = await Dexie.exists(`${database}`);
  if (databaseExists) {
    const db = new Dexie(`${database}`);
    const openedDb = await db.open();

    const resourceTable = openedDb.table(`${table}`);
    return resourceTable?.toCollection()?.count();
  } else {
    return 0;
  }
};

export async function getDatabaseNames() {
  return Dexie.getDatabaseNames();
}

export async function deleteDatabase(databaseName) {
  Dexie.delete(databaseName);
}
