/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useLocalStorage } from 'react-use';
import { BroadcastChannel as BroadcastChannelBackup } from 'broadcast-channel';

export const useDownloadProgress = resourceDownloadWorker => {
  const [inventoryDownloadProgress, setInventoryDownloadProgress] = useState({ progress: 0, error: 0 });
  const [promosDownloadProgress, setPromosDownloadProgress] = useState({ progress: 0, error: 0 });
  const [patientsDownloadProgress, setPatientsDownloadProgress] = useState({ progress: 0, error: 0 });
  const [salesDownloadProgress, setSalesDownloadProgress] = useState({ progress: 0, error: 0 });
  const [priceUpdateProgress, setPriceUpdateProgress] = useState({ progress: 0, error: 0 });
  const [, setPriceUpdateLocal] = useLocalStorage('bloom:priceUpdateProgress');

  const [user] = useLocalStorage('bloom:user');

  if (!user?.is_staff) {
    resourceDownloadWorker?.addEventListener('message', e => {
      const {
        patientsDownloadProgress: patientsDownloadProgressUpdate,
        salesDownloadProgress: salesDownloadProgressUpdate,
        inventoryDownloadProgress: inventoryDownloadProgressUpdate,
        promosDownloadProgress: promosDownloadProgressUpdate
      } = e.data.resourceDownloadProgress;
      if (patientsDownloadProgressUpdate) {
        setPatientsDownloadProgress(patientsDownloadProgressUpdate);
      }
      if (salesDownloadProgressUpdate) {
        setSalesDownloadProgress(salesDownloadProgressUpdate);
      }
      if (inventoryDownloadProgressUpdate) {
        setInventoryDownloadProgress(inventoryDownloadProgressUpdate);
      }
      if (promosDownloadProgressUpdate) {
        setPromosDownloadProgress(promosDownloadProgressUpdate);
      }
    });
  }

  useEffect(() => {
    const BloomBroadcastChannel = typeof BroadcastChannel === 'undefined' ? BroadcastChannelBackup : BroadcastChannel;
    const resourceCachingChannel = new BloomBroadcastChannel('resource-caching-channel');
    resourceCachingChannel.postMessage({
      patients: {
        progress: patientsDownloadProgress.progress
      },
      sales: {
        progress: salesDownloadProgress.progress
      },
      inventory: {
        progress: inventoryDownloadProgress.progress
      },
      promos: {
        progress: promosDownloadProgress.progress
      },
      price: {
        progress: priceUpdateProgress.progress
      }
    });
  }, [
    inventoryDownloadProgress,
    patientsDownloadProgress,
    salesDownloadProgress,
    promosDownloadProgress,
    priceUpdateProgress
  ]);

  useEffect(() => {
    setPriceUpdateLocal(priceUpdateProgress?.progress);
  }, [priceUpdateProgress?.progress]);

  return {
    inventoryDownloadProgress,
    patientsDownloadProgress,
    salesDownloadProgress,
    promosDownloadProgress,
    priceUpdateProgress,
    setPriceUpdateProgress
  };
};

export default useDownloadProgress;
