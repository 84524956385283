import { useState } from 'react';
import { BroadcastChannel as BroadcastChannelBackup } from 'broadcast-channel';
import { BROADCAST_CHANNEL_NAMES } from '../helpers/constants';

function useSalesSyncStatusBroadcastChannel() {
  const [salesSyncInProgress, setSalesSyncInProgress] = useState(false);
  const [salesSyncCompleted, setSalesSyncCompleted] = useState(false);
  const [outboxReport, setOutboxReport] = useState(null);
  const [salesSyncProgress, setSalesSyncProgress] = useState(null);

  const BloomBroadcastChannel = typeof BroadcastChannel === 'undefined' ? BroadcastChannelBackup : BroadcastChannel;

  const salesSyncStatusBroadcastChannel = new BloomBroadcastChannel(
    BROADCAST_CHANNEL_NAMES.SALES_SYNC_STATUS_BROADCAST_CHANNEL
  );

  salesSyncStatusBroadcastChannel.onmessage = e => {
    const { salesSyncInProgress, salesSyncCompleted, outboxReport, salesSyncProgress } = e.data;
    setSalesSyncInProgress(salesSyncInProgress);
    setSalesSyncCompleted(salesSyncCompleted);
    setOutboxReport(outboxReport);
    setSalesSyncProgress(salesSyncProgress);
  };

  return { salesSyncInProgress, salesSyncCompleted, outboxReport, salesSyncProgress };
}

export default useSalesSyncStatusBroadcastChannel;
