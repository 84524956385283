import React, { useState, useEffect } from 'react';
import Notification from './Notification';
import { databaseQueryForProgressTable } from '../../db/utils';
import { Text, useMpharmaI18n } from 'mpharma-i18n';
import { useLiveQuery } from 'dexie-react-hooks';

export default function UnavailableService({ service, online }) {
  const progress = useLiveQuery(() => databaseQueryForProgressTable(service));
  const { translateKey } = useMpharmaI18n();
  const [notificationMessage, setNotificationMessage] = useState('');
  const [shouldRefresh, setShouldRefresh] = useState(false);
  const [showNotification, setShowNotification] = useState(false);

  useEffect(() => {
    if (progress) {
      //Empty service
      if (progress.progress === 100 && progress[`empty_${service}`]) {
        setShowNotification(true);
        setNotificationMessage(translateKey('home.emptyInventoryMessage'));
        setShouldRefresh(false);
      }

      //Non-success response after retries have been exhausted
      if (progress.error > 0) {
        setShowNotification(true);
        setNotificationMessage(translateKey('home.troubleFetchingInventory'));
        setShouldRefresh(true);
      }

      //Service is available and not done downloading
      if (progress.service_available && progress.progress < 100) {
        setShowNotification(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [progress, online, service]);

  return (
    showNotification && (
      <Notification
        action={shouldRefresh && <Text i18nKey="home.refreshPage">Refresh page</Text>}
        link="/home"
        message={notificationMessage}
        critical
        handleClose={() => setShowNotification(false)}
      />
    )
  );
}
