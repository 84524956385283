import * as singleSpa from 'single-spa';
import { handleMicroAppUnmount, setupMicroApp } from './utils';

const facilityInsightsUrl =
  process.env.NODE_ENV === 'development'
    ? 'http://localhost:4006/static/js/main.js'
    : process.env.REACT_APP_ANALYTICS_JS_BUNDLE;

const facilityInsightsElementId = 'facility-insights';

const loadFacilityInsightsApp = async () => {
  return setupMicroApp(facilityInsightsUrl, facilityInsightsElementId);
};

const application = {
  update: () => Promise.resolve(), //update function
  bootstrap: () => Promise.resolve(), //bootstrap function
  mount: () => Promise.resolve(loadFacilityInsightsApp()), //mount function
  unmount: () => Promise.resolve(handleMicroAppUnmount()) //unmount function
};

export const registerFacilityInsightsApp = () => {
  singleSpa.registerApplication('facility-insights', application, location => {
    return location.pathname.startsWith('/facility-insights');
  });
};
