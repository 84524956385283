import { useState, useEffect, useRef } from 'react';
import { API_ROOT } from '../helpers/constants';
import { get } from '../admin/api/';

function useStockUploadTracker(stockUpload) {
  const POLL_INTERVAL = 5000;
  let timer = useRef();
  const [progress, setProgress] = useState(0);
  const [errorCount, setErrorCount] = useState(0);
  const [isCompleted, setCompletion] = useState(false);
  const id = stockUpload && stockUpload.batch_sync_number;

  useEffect(() => {
    if (id && !isCompleted) {
      timer.current = setTimeout(() => getStockUploadProgress(), POLL_INTERVAL);
    }
    return () => {
      clearTimeout(timer);
    };
  });

  function getStockUploadProgress() {
    function successHandler(response) {
      const {
        num_of_records_processed,
        num_of_records,
        num_of_records_processed_successfully,
        stock_take_error
      } = response;
      const progressUpdate = Number(((num_of_records_processed_successfully / num_of_records) * 100).toFixed(0));
      const isCompletedUpdate = num_of_records === num_of_records_processed;
      setProgress(progressUpdate);
      setCompletion(isCompletedUpdate);
      setErrorCount(stock_take_error.length);
    }

    function errorHandler(err) {
      console.error('there was an error', err);
    }

    get(`${API_ROOT}/inventory-bff/stock-takes-poll/${id}/`, successHandler, errorHandler);
  }

  return [progress, errorCount, isCompleted];
}

export default useStockUploadTracker;
