import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import Button from 'mpharma-components-library/lib/Button';
import { ReactComponent as UpdateCacheIcon } from '../../images/UpdateCacheIcon.svg';
import { ReactComponent as CloseIcon } from '../../images/CloseBlackIcon.svg';
import { Text, useMpharmaI18n } from 'mpharma-i18n';
import { selectStyles, StylesWrapper, StylesWrapperClasses as classes } from '../styles';
import TextInput from 'mpharma-components-library/lib/TextInput';
import { clearCacheData, deleteAllIndexedDBDatabasesExceptOutbox } from '../../db/utils';
import { GROUPED_UPDATING_REASONS_OPTIONS } from '../../helpers/constants';
import { useMixpanel } from './Mixpanel/Provider';
import { logOut } from '../state/auth/reducers';
import { ClearCacheLoadingModal } from './ClearCacheLoadingModal';
import { deleteAllCookies } from '../../helpers/utils';

export function UpdateAppDialog({ handleClose }) {
  const { translateKey } = useMpharmaI18n();
  const [selectedOption, setSelectedOption] = useState(null);
  const [toggleInput, setToggleInput] = useState(false);
  const [otherReasons, setOtherReasons] = useState(null);
  const mixpanel = useMixpanel();
  const [clearCacheLoading, setClearCacheLoading] = useState(false);

  const callback = () => {
    deleteAllCookies();
    sessionStorage.clear();
    localStorage.clear();
    logOut();
  };

  async function runAsyncFuncsInOrder() {
    setClearCacheLoading(true);
    try {
      await deleteAllIndexedDBDatabasesExceptOutbox(() => setClearCacheLoading(false), callback);
      await clearCacheData();
    } catch (error) {
      console.error('Error during async functions:', error);
    }
  }

  async function handleUpdate() {
    //clear site data except for outbox db and prompt user to log in
    const isOthers = selectedOption?.value === translateKey('home.updateApplication.reasons.others');
    mixpanel.track('Update Bloom Reasons', {
      selectReasons: selectedOption?.value,
      ...(isOthers && { otherReasons: otherReasons })
    });
    await runAsyncFuncsInOrder();
  }

  const handleReasonChange = option => {
    setSelectedOption(option);
  };

  const disableProceedButton = selectedOption => {
    return (
      selectedOption === null ||
      selectedOption === undefined ||
      (selectedOption?.value === translateKey('home.updateApplication.reasons.others') && !otherReasons)
    );
  };

  const handleOtherReasonsInputChange = e => {
    setOtherReasons(e.target.value);
  };

  useEffect(() => {
    if (selectedOption?.value === translateKey('home.updateApplication.reasons.others')) {
      setToggleInput(true);
    } else {
      setToggleInput(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOption]);

  return (
    <StylesWrapper>
      {!clearCacheLoading ? (
        <div className={classes.container} data-testid="update-app-dialog">
          <CloseIcon onClick={() => handleClose('openUpdateModal')} className={classes.icon} />
          <div className={classes.top}>
            <UpdateCacheIcon />
          </div>
          <div className={classes.top}>
            <p className={classes.title}>
              <Text i18nKey="home.updateApplication">Update application or clear cache</Text>
            </p>
          </div>
          <div className={classes.coloredBody}>
            <p className={classes.description}>{translateKey('home.updateApplication.description')}</p>
          </div>
          <div className={classes.body}>
            <ul>
              <li className={classes.description}>{translateKey('home.updateApplication.request.reasons')}</li>
            </ul>
          </div>
          <div>
            <p className={classes.reasonsTitle}>{translateKey('home.updateApplication.reasons.title')} </p>
          </div>
          <Select
            aria-label="select update reason"
            placeholder={<Text i18nKey="home.modals.updateApplication.placeholder">Select a reason</Text>}
            name="updateApp"
            options={GROUPED_UPDATING_REASONS_OPTIONS}
            onChange={handleReasonChange}
            data-testid="update-reasons-select"
            defaultValue={selectedOption}
            components={{ IndicatorSeparator: null }}
            theme={theme => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary25: 'rgba(88, 124, 200, 0.1)',
                primary: '#35CAE2'
              }
            })}
            styles={selectStyles}
            isClearable={true}
          />
          {toggleInput && (
            <div className={classes.otherReasons}>
              <TextInput
                className={classes.otherReasonsInput}
                autoFocus
                label={translateKey('home.updateApplication.reasons.others.label')}
                style={{
                  height: '40px',
                  borderRadius: '6px',
                  border: '1px solid #E3E6EA',
                  width: '100%'
                }}
                type="text"
                name="otherReasonsInput"
                data-testid="other-reasons-input"
                fullWidth
                onChange={handleOtherReasonsInputChange}
                value={otherReasons}
              />
            </div>
          )}
          <div className={classes.groupButton}>
            <Button
              variant={'secondary'}
              style={{ borderRadius: '4px' }}
              onClick={handleClose}
              data-testid="cancel-btn"
            >
              <Text i18nKey="common.cancel">Cancel</Text>
            </Button>
            <Button
              variant={'primary'}
              style={{ marginLeft: 15, borderRadius: '4px' }}
              onClick={handleUpdate}
              data-testid="update-btn"
              disabled={disableProceedButton(selectedOption)}
            >
              <Text i18nKey="common.proceed">Proceed</Text>
            </Button>
          </div>
        </div>
      ) : (
        <ClearCacheLoadingModal classes={classes} open={clearCacheLoading} />
      )}
    </StylesWrapper>
  );
}

UpdateAppDialog.propTypes = {
  handleClose: PropTypes.func.isRequired
};

export default UpdateAppDialog;
