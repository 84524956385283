import { get } from '../../../admin/api/';
import { API_ROOT, INVENTORY_BFF_URL, LOGOUT_URL } from '../../../helpers/constants';
import { deleteDatabase, getDatabaseNames, getTableCount } from '../../../db/indexedDbUtils';
import db from '../../../db/BloomDatabase';

export const REQUEST_USER = 'REQUEST_USER';
export const USER_SUCCESS = 'USER_SUCCESS';
export const USER_FAILURE = 'USER_FAILURE';
export const LOG_OUT = 'LOG_OUT';

export const getUser = async () => {
  try {
    return await get(`${API_ROOT}/users/userinfo`);
  } catch (error) {
    return error;
  }
};

export const requestSalesHistoryFile = async salesHistoryObjectId => {
  try {
    return await get(`${INVENTORY_BFF_URL}/sales/download/status/${salesHistoryObjectId}/`);
  } catch (error) {
    return error;
  }
};

export const requestInventoryLevelDownloadFile = async inventoryLevelDownloadObjectId => {
  try {
    return await get(`${API_ROOT}/inventory-ms/inventory-downloads/${inventoryLevelDownloadObjectId}/`);
  } catch (error) {
    return error;
  }
};

/**
 * Clears IndexedDB and localstorage only on test
 * but keeps outbox if there is data
 */
export async function clearBloomDB(facilityId) {
  const outboxCount = await getTableCount('outbox', 'outbox');
  const dbNames = await getDatabaseNames();
  localStorage.setItem('bloom:currentUserFacilityId', facilityId);
  if (outboxCount > 0) {
    dbNames.forEach(dbName => {
      if (dbName !== 'outbox') {
        deleteDatabase(dbName);
      }
    });
  } else {
    dbNames.forEach(dbName => {
      deleteDatabase(dbName);
    });
  }
  // recreate db
  db.createDB();
}

export const logOut = async () => {
  localStorage.removeItem('inventoryLevelDownloadTrackerId');
  localStorage.setItem('bloom:offlineLoggedOut', true);
  localStorage.setItem('redirected', 'yes');
  localStorage.removeItem('bloom:facility');
  localStorage.removeItem('isBulkUploader');
  window.location.href = `${LOGOUT_URL}`;
  return { type: LOG_OUT };
};

export const redirectAuthLogin = () => {
  localStorage.setItem('bloom:offlineLoggedOut', false);
  localStorage.setItem('redirected', 'yes');
  window.location.href = `${LOGOUT_URL}?redirectUrl=${window.location.href}`;
};

const INITIAL_STATE = {
  loading: false,
  user: {},
  error: false
};

const AuthReducer = (state = INITIAL_STATE, action = { type: '' }) => {
  const { type, payload } = action;

  switch (type) {
    case REQUEST_USER:
      return { ...INITIAL_STATE, loading: true };
    case USER_SUCCESS:
      return { ...INITIAL_STATE, user: payload };
    case USER_FAILURE:
      return { ...INITIAL_STATE, error: true };
    case LOG_OUT:
      return { ...INITIAL_STATE };
    default:
      return state;
  }
};

export default AuthReducer;
